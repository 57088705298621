import { SafePipe } from "./pipes/safe.pipe";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TagInputModule } from "ngx-chips";
import {
  ChartAllModule,
  AccumulationChartAllModule,
  RangeNavigatorAllModule,
} from "@syncfusion/ej2-angular-charts";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatSnackBarModule, MatProgressSpinnerModule } from "@angular/material";
import { ResizeService } from "@syncfusion/ej2-angular-grids";
import {
  PageService,
  SortService,
  FilterService,
  GroupService,
} from "@syncfusion/ej2-angular-grids";
import {
  LinkAnnotationService,
  BookmarkViewService,
  MagnificationService,
  ThumbnailViewService,
  ToolbarService,
  NavigationService,
  AnnotationService,
  TextSearchService,
  TextSelectionService,
  PrintService,
  PdfViewerComponent,
  PdfViewerAllModule,
} from "@syncfusion/ej2-angular-pdfviewer";
import { AuthService } from "./network/_services/auth.service";
import { NetworkService } from "./network/_services/network";
import { WindowRef } from "./helpers/windowref";
import { RequestCache } from "./network/_services/request-cache";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardLayoutComponent } from "./dashboard-layout/dashboard-layout.component";
import { ProfileComponent } from "./profile/profile.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SignInComponent } from "./sign-in/sign-in.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { SignUpDoneComponent } from "./sign-up-done/sign-up-done.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { RewardsComponent } from "./rewards/rewards.component";
import { TreatFileComponent } from "./treat-file/treat-file.component";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ChatpageComponent } from "./chatpage/chatpage.component";
import { MemosComponent } from "./memos/memos.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { InternalDocumentComponent } from "./internal-document/internal-document.component";
import { ViewArchiveComponent } from "./view-archive/view-archive.component";
import { FileManagerComponent } from "./file-manager/file-manager.component";
import { SimpleFileUploadComponent } from "./simple-file-upload/simple-file-upload.component";
import { ViewFilesDialogComponent } from "./view-files-dialog/view-files-dialog.component";
import { UserIdleModule } from "angular-user-idle";
import { HtmmlViewerComponent } from "./htmml-viewer/htmml-viewer.component";
import { EscapeHtmlPipe } from "./pipes/keep-html.pipe";
import { DocumentSearchComponent } from "./document-search/document-search.component";
import { ExportAsModule } from "ngx-export-as";
import { CalendarModule } from "@syncfusion/ej2-angular-calendars";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import { DateTimePickerModule } from "@syncfusion/ej2-angular-calendars";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { LeaveMgtComponent } from "./leave-mgt/leave-mgt.component";
import { ExportAsService } from "./_services/export-service";
import { ViewLeaveComponent } from "./view-leave/view-leave.component";
import { InterMinistryModule } from "./inter-ministry/inter-ministry.module";
import { SharedModule } from "./shared/shared.module";
import { InterMinistryService } from "./_services/inter-ministry.service";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { EmojiModule } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { RbacAllowDirective } from "./directives/rbac-allow.directive";
import { PreviewSubmissionComponent } from "./preview-submission/preview-submission.component";
import { NewAccountCorporateReviewComponent } from "./new-account-corporate-review/new-account-corporate-review.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { JQ_TOKEN } from "./network/_services/jQuery.service";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { RbacDisallowDirective } from "./directives/rbac-disallow.directive";
import { ToastrModule } from "ngx-toastr";
let jQuery = window["$"];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardLayoutComponent,
    ProfileComponent,
    ResetPasswordComponent,
    SignInComponent,
    SignUpComponent,
    SignUpDoneComponent,
    VerifyEmailComponent,
    RewardsComponent,
    TreatFileComponent,
    ChatpageComponent,
    MemosComponent,
    ChangePasswordComponent,
    InternalDocumentComponent,
    ViewArchiveComponent,
    FileManagerComponent,
    SimpleFileUploadComponent,
    ViewFilesDialogComponent,
    HtmmlViewerComponent,
    EscapeHtmlPipe,
    SafePipe,
    DocumentSearchComponent,
    LeaveMgtComponent,
    ViewLeaveComponent,
    PreviewSubmissionComponent,
    NewAccountCorporateReviewComponent,
    FileUploadComponent,
    RbacDisallowDirective,
  ],
  imports: [
    TagInputModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgxDocViewerModule,
    MatCarouselModule,
    CarouselModule,
    ChartAllModule,
    AccumulationChartAllModule,
    RangeNavigatorAllModule,
    UserIdleModule.forRoot({ idle: 1200, timeout: 2, ping: 1 }),
    CalendarModule,
    DatePickerModule,
    DateTimePickerModule,
    ExportAsModule,
    ButtonModule,
    PdfViewerAllModule,
    SharedModule.forRoot(),
    InterMinistryModule,
    PickerModule,
    EmojiModule,
    PdfJsViewerModule,
    NgSelectModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
  ],
  providers: [
    InterMinistryService,
    AuthService,
    NetworkService,
    ExportAsService,
    WindowRef,
    PageService,
    SortService,
    FilterService,
    GroupService,
    ResizeService,
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    AnnotationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    RequestCache,
    { provide: JQ_TOKEN, useValue: jQuery },
    //{provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: NetworkService, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [AlertDialogComponent],
})
export class AppModule {}
