import { Component, OnInit, ViewChild } from "@angular/core";
import { PageSettingsModel } from "@syncfusion/ej2-grids";
import { Router } from "@angular/router";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { MatDialog } from "@angular/material";
import { AlertHelper } from "../helpers/alert-helper";
import { User } from "../models/user";
import { NetworkService } from "../network/_services/network";
import { Utils } from "../helpers/utils";
import moment from "moment";

@Component({
  selector: "app-leave-mgt",
  templateUrl: "./leave-mgt.component.html",
  styleUrls: ["./leave-mgt.component.scss"],
})
export class LeaveMgtComponent implements OnInit {
  public pageSettings: PageSettingsModel;
  leaveHistoryData = [
    {
      Ref: "#230012HJ",
      Type: "Casual",
      Start: "10-11-2019",
      End: "20-11-2019",
      Status: "Pending",
    },
  ];
  public toolbar: string[];
  @ViewChild("leaveHistoryGrid", { static: false })
  public leaveHistoryGrid: GridComponent;
  leaveStatsLoader = false;
  alertHelper: AlertHelper;
  leaveDetailsModal = false;
  leaveDetailsLoader = false;
  isHR = false;
  isHOD = false;
  isLM = false;
  isInitiator = false;
  showRequests = false;
  submit_loader = false;
  submit_loader_text: string;
  // tslint:disable-next-line: max-line-length
  Dates: { LeaveStartDate: Date; LeaveEndDate: Date } = {
    LeaveStartDate: null,
    LeaveEndDate: null,
  };
  leaveApplicationData: {
    LeaveTypeId: number;
    LeaveStartDate: string;
    LeaveEndDate: string;
    ContactPhoneNumber: string;
    ReliefStaffId: number;
    LineManagerId: number;
  } = {
    LeaveTypeId: null,
    LeaveStartDate: null,
    LeaveEndDate: null,
    ContactPhoneNumber: "",
    ReliefStaffId: null,
    LineManagerId: null,
  };
  leaveTypes: Array<{}> = [];
  fullLeaveData: {};
  public fetchedDeptUsers: { [key: string]: Object }[];
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private network: NetworkService
  ) {
    this.alertHelper = new AlertHelper(dialog);
  }

  ngOnInit() {
    this.fetchLeaveTypes();
    this.fetchTheDepartmentUsers();
  }
  showFSLoader(text: string = "Processing...") {
    this.submit_loader = true;
    this.submit_loader_text = text;
  }

  dismissFSLoader() {
    this.submit_loader = false;
    this.submit_loader_text = "";
  }

  rowDblClickOpenFile(args) {
    var sub_ref: string = args.rowData["RefNo"];
    sub_ref = sub_ref.replace("#", "");
    if (sub_ref) {
      this.router.navigate(["/view-leave", sub_ref]);
    }
  }

  leaveSummary = { daysAllowed: 0, daysUsed: 0, daysLeft: 0 };

  getLeaveSummary() {
    this.leaveStatsLoader = true;
    this.network
      .sendRequest({}, NetworkService.FETCH_LEAVE_SUMMARY)
      .subscribe((result) => {
        if (!result["error"]) {
          this.leaveStatsLoader = false;
          var Rez = JSON.parse(result["response"]);
          this.leaveSummary.daysAllowed = Rez.DaysAllowed;
          this.leaveSummary.daysUsed = Rez.DaysUsed;
          this.leaveSummary.daysLeft = Rez.DaysLeft;
          this.getLeaveApplications();
        } else {
          this.getLeaveSummary();
        }
      });
  }

  LeaveApplications: any;
  PendingLeaveApplications: Array<{}>;
  ReviewedLeaveApplications: Array<{}>;

  getLeaveApplications() {
    this.leaveStatsLoader = true;
    this.network
      .sendRequest({}, NetworkService.FETCH_LEAVE_APPLICATIONS)
      .subscribe((result) => {
        if (!result["error"]) {
          this.leaveStatsLoader = false;
          this.LeaveApplications = JSON.parse(result["response"]);
          console.log(this.LeaveApplications);
          this.PendingLeaveApplications = [];
          this.ReviewedLeaveApplications = [];
          this.LeaveApplications.forEach((element) => {
            if (element["LeaveStatus"] == "Pending") {
              this.PendingLeaveApplications.push(element);
            } else {
              this.ReviewedLeaveApplications.push(element);
            }
          });
          console.log(this.PendingLeaveApplications);
          console.log(this.ReviewedLeaveApplications);
        } else {
          this.getLeaveApplications();
        }
      });
  }

  fetchLeaveTypes() {
    this.leaveStatsLoader = true;
    this.network
      .sendRequest({}, NetworkService.GET_LEAVE_TYPES)
      .subscribe((result) => {
        if (!result["error"]) {
          this.leaveStatsLoader = false;
          console.log(JSON.parse(result["response"]));
          this.leaveTypes = JSON.parse(result["response"]);
        }
      });
  }

  fetchTheDepartmentUsers() {
    this.leaveStatsLoader = true;
    this.network
      .sendRequest({}, NetworkService.FETCH_USERS_AND_DEPT_FOR_LEAVE)
      .subscribe((result) => {
        if (!result["error"]) {
          this.leaveStatsLoader = false;
          this.fetchedDeptUsers = JSON.parse(result["response"]);
          this.fetchTheDepartmentHeads();
        }
      });
  }

  fetchedRequests: any;
  leaveStatusText = "";
  statusToDisplay = "";

  fetchTheLeaveApplicationRequests() {
    this.leaveStatsLoader = true;
    this.network
      .sendRequest({}, NetworkService.FETCH_LEAVE_REQUESTS)
      .subscribe((result) => {
        if (!result["error"]) {
          this.leaveStatsLoader = false;
          this.fetchedRequests = JSON.parse(result["response"]);
          console.log(this.fetchedRequests);
          this.fetchedRequests.forEach((element) => {
            if (element.isHhr || element.isHod || element.isLineManager) {
              this.showRequests = true;
              this.leaveStatusText = "Your response";
            }
            if (element.isLineManager) {
              this.statusToDisplay = "LineManagerApproval";
              if (element.LineManagerApproval == 1) {
                element.LineManagerApproval = "Pending";
              } else if (element.LineManagerApproval == 2) {
                element.LineManagerApproval = "Approved";
              } else if (element.LineManagerApproval == 3) {
                element.LineManagerApproval = "Disapproved";
              }
            } else if (element.isHod) {
              this.statusToDisplay = "HodApproval";
              if (element.HodApproval == 1) {
                element.HodApproval = "Pending";
              } else if (element.HodApproval == 2) {
                element.HodApproval = "Approved";
              } else if (element.HodApproval == 3) {
                element.HodApproval = "Disapproved";
              }
            } else if (element.isHhr) {
              this.statusToDisplay = "HhrApproval";
              if (element.HhrApproval == 1) {
                element.HhrApproval = "Pending";
              } else if (element.HhrApproval == 2) {
                element.HhrApproval = "Approved";
              } else if (element.HhrApproval == 3) {
                element.HhrApproval = "Disapproved";
              }
            }
          });
          this.getLeaveSummary();
        }
      });
  }

  HOD: any;
  HR: any;
  HOG: any;

  fetchTheDepartmentHeads() {
    this.leaveStatsLoader = true;
    this.network
      .sendRequest({}, NetworkService.FETCH_DEPT_HEAD_AND_HR_HEAD_FOR_LEAVE)
      .subscribe((result) => {
        if (!result["error"]) {
          this.leaveStatsLoader = false;
          var Rez = JSON.parse(result["response"]);
          this.HOD = JSON.parse(Rez.HOD)[0];
          this.HR = JSON.parse(Rez.HR)[0];
          this.HOG = JSON.parse(Rez.HOG)[0];
          if (
            this.HOD.email == User.getCurrentUser().email ||
            this.HR.email == User.getCurrentUser().email
          ) {
            this.showRequests = true;
          }

          if (this.HOD.email == User.getCurrentUser().email) {
            this.isHOD = true;
            localStorage.setItem("isHOD", "true");
          } else {
            localStorage.setItem("isHOD", "false");
            // localStorage.getItem()
          }

          this.fetchTheLeaveApplicationRequests();
        }
      });
  }

  today = new Date();

  validateApplicationData() {
    if (this.leaveApplicationData.LeaveTypeId == null) {
      this.alertHelper.showOkAlert("Error", "Leave type needs to be selected");
      return;
    } else if (this.Dates.LeaveStartDate == null) {
      this.alertHelper.showOkAlert(
        "Error",
        "Leave start date needs to be selected"
      );
      return;
    } else if (this.Dates.LeaveEndDate == null) {
      this.alertHelper.showOkAlert(
        "Error",
        "Leave end date needs to be selected"
      );
      return;
    } else if (this.Dates.LeaveEndDate <= this.Dates.LeaveStartDate) {
      this.alertHelper.showOkAlert(
        "Error",
        "Wrong dates selected, please check the dates"
      );
      return;
    } else if (this.Dates.LeaveStartDate <= this.today) {
      this.alertHelper.showOkAlert(
        "Error",
        "Wrong start date selected, please check the dates"
      );
      return;
    } else if (this.leaveApplicationData.ContactPhoneNumber === "") {
      this.alertHelper.showOkAlert(
        "Error",
        "Contact phone number needs to be entered"
      );
      return;
    } else if (
      !Utils.isValidPhone(this.leaveApplicationData.ContactPhoneNumber)
    ) {
      this.alertHelper.showOkAlert(
        "Error",
        "Contact phone number format is incorrect"
      );
      return;
    } else if (this.leaveApplicationData.ReliefStaffId == null) {
      this.alertHelper.showOkAlert(
        "Error",
        "A relief staff needs to be selected"
      );
      return;
    } else if (this.leaveApplicationData.LineManagerId == null) {
      this.alertHelper.showOkAlert(
        "Error",
        "Line manager needs to be selected"
      );
      return;
    }

    this.leaveApplicationData.LeaveStartDate = moment(
      this.Dates.LeaveStartDate
    ).format("DD-MM-YYYY");
    this.leaveApplicationData.LeaveEndDate = moment(
      this.Dates.LeaveEndDate
    ).format("DD-MM-YYYY");
    this.showFSLoader("Submitting Application...");
    this.network
      .sendRequest(
        this.leaveApplicationData,
        NetworkService.CREATE_LEAVE_APPLICATION
      )
      .subscribe(
        (response) => {
          if (!response["error"]) {
            this.dismissFSLoader();
            this.alertHelper.showOkAlert(
              "Success",
              response["response"],
              () => {
                // tslint:disable-next-line: max-line-length
                this.leaveApplicationData = {
                  LeaveTypeId: null,
                  LeaveStartDate: null,
                  LeaveEndDate: null,
                  ContactPhoneNumber: "",
                  ReliefStaffId: null,
                  LineManagerId: null,
                };
                this.router.navigate(["/dashboard"]);
              },
              3000
            );
          } else {
            this.dismissFSLoader();
            this.alertHelper.showOkAlert("Error", response["response"]);
          }
        },
        (error) => {
          this.dismissFSLoader();
          this.alertHelper.showErrorAlert();
        }
      );

    // Send data for application to be created and logged.

    // this.alertHelper.showOkAlert('Success', 'Leave Application Created');
  }

  closeLeaveDetailsModal() {
    this.leaveDetailsModal = false;
  }

  // Leave tracking module starts here

  track_details = [
    {
      ref_no: "#889d9SW",
      subject: "A Subject",
      LM: 0,
      HOD: 0,
      HR: 0,
      notifications: [
        {
          stage: "Acknowlegdement",
          n_date: "2019/2/19",
          in_charge: "Olu Jacobs",
          status: "Completed",
        },
      ],
    },
    {
      ref_no: "#889d9SW",
      subject: "A Subject",
      LM: 1,
      HOD: 0,
      HR: 0,
      notifications: [
        {
          stage: "Acknowlegdement",
          n_date: "2019/2/19",
          in_charge: "Olu Jacobs",
          status: "Completed",
        },
      ],
    },
    {
      ref_no: "#889d9SW",
      subject: "A Subject",
      LM: 1,
      HOD: 2,
      HR: 0,
      notifications: [
        {
          stage: "Acknowlegdement",
          n_date: "2019/2/19",
          in_charge: "Olu Jacobs",
          status: "Completed",
        },
      ],
    },
    {
      ref_no: "#889d9SW",
      subject: "A Subject",
      LM: 1,
      HOD: 1,
      HR: 0,
      notifications: [
        {
          stage: "Acknowlegdement",
          n_date: "2019/2/19",
          in_charge: "Olu Jacobs",
          status: "Completed",
        },
      ],
    },
    {
      ref_no: "#889d9SW",
      subject: "A Subject",
      LM: 1,
      HOD: 1,
      HR: 2,
      notifications: [
        {
          stage: "Acknowlegdement",
          n_date: "2019/2/19",
          in_charge: "Olu Jacobs",
          status: "Completed",
        },
      ],
    },
    {
      ref_no: "#889d9SW",
      subject: "A Subject",
      LM: 1,
      HOD: 1,
      HR: 1,
      notifications: [
        {
          stage: "Acknowlegdement",
          n_date: "2019/2/19",
          in_charge: "Olu Jacobs",
          status: "Completed",
        },
      ],
    },
  ];

  getFullDate(dateString) {
    return moment(dateString).format("MMMM Do YYYY, h:mm a");
  }
  // Leave Tracking module ends here
}
